//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, TableState, Column, Row, TableInstance } from 'react-table';
import { SortArrowDown, SortArrowUp, SortNeutral } from './assets/NucleusTableSVGs';
import TablePagination from './TablePagination';
interface NucleusTableProps {
  tableStyles?: React.CSSProperties;
  tbodyStyles?: React.CSSProperties;
  stickyThead?: boolean;
  columns: Column<object>[];
  data: object[];
  pageSizeNumber?: number;
  checkForDisabled?: boolean;
  onRowClick?: (event: { title: string; row: object }) => void;
}
interface TableInstanceD extends TableInstance<{ show: boolean }> {
  page: Row<object>[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageCount: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  state: TableState<object> & {
    pageIndex: number;
    pageSize: number;
  };
}
const NucleusPaginatedTable: React.FC<NucleusTableProps> = ({
  tableStyles,
  tbodyStyles,
  stickyThead,
  columns,
  data,
  pageSizeNumber = 20,
  checkForDisabled = false,
  onRowClick = () => {},
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageSize: pageSizeNumber } as Partial<TableState<object>> }, // Start with the second option, 20
    useSortBy,
    usePagination,
  ) as TableInstanceD;
  const [limit, setLimit] = useState<number>(20);
  const renderSortIcon = useCallback(
    (column) => {
      if (!column.isSorted) {
        return <SortNeutral />;
      }
      if (column.isSortedDesc) {
        return <SortArrowDown />;
      }
      return <SortArrowUp />;
    },
    [headerGroups],
  );

  const handleLimitChange = (event) => {
    const newLimit = Number(event.target.value);
    setLimit(newLimit);
  };

  useEffect(() => {
    setPageSize(limit);
  }, [limit, setPageSize]);

  return (
    <div>
      <table {...getTableProps()} className="b-table b-table-colored b-table-hover b-table-active" style={tableStyles}>
        <thead className={stickyThead ? 'nucleus-table-header sticky-thead' : 'nucleus-table-header'}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column) =>
                  column.show !== false && (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className +  (column.centerAlign ? ' center-align' : '')}>
                      <span className="nucleus-table-header">
                        {column.render('Header')}
                        {column.canSort && renderSortIcon(column)}
                      </span>
                    </th>
                  ),
              )}
            </tr>
          ))}
        </thead>
        <tbody style={tbodyStyles} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps({
                  onClick: () => onRowClick({ title: 'details', row }),
                })}
                style={{
                  color: checkForDisabled && (row.original as { disabled: boolean }).disabled ? '#888' : undefined,
                }}
              >
                {row.cells.map(
                  (cell) =>
                    (cell.column as Column<object> & { show?: boolean }).show !== false && (
                      <td {...cell.getCellProps()} className={"nucleus-row" + (cell.column.centerAlign ? ' center-align' : '')}>
                        {cell.render('Cell')}
                      </td>
                    ),
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label>Results per page:</label>
        <select
          value={limit}
          onChange={handleLimitChange}
          style={{
            display: 'inline',
            width: 55,
            fontSize: 12,
            height: 25,
            marginLeft: 5,
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className="pagination">
        <TablePagination currentPage={pageIndex + 1} rowsPerPage={limit} totalData={data.length} key={'pagination'} onPageSelected={(page) => gotoPage(page - 1)} />
      </div>
    </div>
  );
};

export default NucleusPaginatedTable;
