export const AccountConfigCheckBoxLine = ({ id, state, label, onChangeAction }: { id: string; state: boolean; label: string; onChangeAction: (state: boolean) => void }) => {
  console.log('AccountConfigCheckBoxLine');
  const onChange = (e) => {
    onChangeAction && onChangeAction(e.target.checked);
  };
  return (
    <div className="row vbottom-align no-margin bit-top-separated">
      <div className="col s10 no-margin">
        <span className="nucleus-labels-small">{label}</span>
      </div>
      <div className="col s2 no-margin">
        <span>
          <input
            id={id}
            type="checkbox"
            className="filled-in"
            defaultChecked={state}
            onChange={onChange}
          />
          <label htmlFor={id}>&nbsp;</label>
        </span>
      </div>
    </div>
  );
};
