import { SpinnerCircular } from 'spinners-react';
import CareAccountStore from '../../../stores/CareAccountStore';
import { useEffect, useRef, useState } from 'react';
import AuthStore from '../../../stores/AuthStore';
import Message from '../../../utils/Message';
import { AccountData } from './AccountSettings';

export type AccountStringsData = {
  ClientRoleName: string;
  ClientRoleNamePlural: string;
  FamilyRoleName: string;
  FamilyRoleNamePlural: string;
  GroupsRoleName: string;
  GroupsRoleNamePlural: string;
  UserRoleName: string;
  UserRoleNamePlural: string;

  OnDutyName: string;
  CallButtonText: string;

  CallButtonText2: string;

  EmergencyButtonText: string;

  MemoryBoxButtonText: string;
  FamilyCallButtonText: string;
  BiometricsButtonText: string;
  AddFamilyButtonText: string;

  OffHoursMessage: string;

  RequestTimeoutMessageTextForPatient: string;
  GroupsButtonText: string;
  GroupsPluralButtonText: string;
};

export const AccountStrings = ({ accountId }: { accountId: string }) => {
  const txtClientRoleName = useRef<HTMLInputElement>();
  const txtClientRoleNamePlural = useRef<HTMLInputElement>();
  const txtFamilyRoleName = useRef<HTMLInputElement>();
  const txtFamilyRoleNamePlural = useRef<HTMLInputElement>();
  const txtGroupsRoleName = useRef<HTMLInputElement>();
  const txtGroupsRoleNamePlural = useRef<HTMLInputElement>();
  const txtUserRoleName = useRef<HTMLInputElement>();
  const txtUserRoleNamePlural = useRef<HTMLInputElement>();
  const txtOnDutyName = useRef<HTMLInputElement>();
  const txtCallButtonText = useRef<HTMLInputElement>();
  const txtCallButtonText2 = useRef<HTMLInputElement>();
  const txtEmergencyBtnLabel = useRef<HTMLInputElement>();
  const txtMemoryBoxLabel = useRef<HTMLInputElement>();
  const txtFamilyMemberLabel = useRef<HTMLInputElement>();
  const txtBiometricsLabel = useRef<HTMLInputElement>();
  const txtAddFamilyButtonLabel = useRef<HTMLInputElement>();
  const txtTimeoutMessage = useRef<HTMLInputElement>();
  const txtOffHoursMessage = useRef<HTMLInputElement>();
  const selectAccountLanguage = useRef<HTMLSelectElement>();
  const selectNewAccountLanguage = useRef<HTMLSelectElement>();
  const [loadingStrings, setLoadingStrings] = useState<boolean>(false);
  const [accountStringsData, setAccountStringsData] = useState<any>();
  const [accountData, setAccountData] = useState<AccountData>();
  const [accountSelectedLanguage, setAccountSelectedLanguage] = useState<string>();
  const [accountActiveLanguages, setAccountActiveLanguages] = useState<any[]>([]);
  const [accountAvailableLanguages, setAccountAvailableLanguages] = useState<any[]>([]);
  const [accountCallTimeoutMessageLength, setAccountCallTimeoutMessageLength] = useState<number>(0);
  const [updatingMessage, setUpdatingMessage] = useState<boolean>(false);

  const refreshAccountData = () => {
    CareAccountStore.getAccountData({
      AccountID: accountId,
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
    });
  };

  const onGetAccountDataAction = (response: AccountData & { ok: boolean }) => {
    if (response && response.ok) {
      //console.log("Response Unica ", response);
      if (response.LocaleTag) {
        selectAccountLanguage.current.value = response.LocaleTag;
        setAccountSelectedLanguage(response.LocaleTag);
        CareAccountStore.getAccountStringsConfig(accountId, response.LocaleTag.toUpperCase(), (response) => {
          console.log('CareAccountStore.getAccountStringsConfig callback', response);
          setLoadingStrings(false);
          if (response.ok) {
            loadAccountStringsConfig(response);
          }
        });
      } else {
        selectAccountLanguage.current.value = '';
      }
    } else {
      Message.show('Sorry, There was a problem getting the account information. Please try again.');
    }
  };

  const loadAccountStringsConfig = (accountStringsData: AccountStringsData) => {
    console.log('loadAccountStringsConfig >', accountStringsData);

    if (accountStringsData && txtClientRoleName.current) {
      setAccountStringsData(accountStringsData);

      txtClientRoleName.current.value = accountStringsData.ClientRoleName;
      txtClientRoleNamePlural.current.value = accountStringsData.ClientRoleNamePlural;
      txtFamilyRoleName.current.value = accountStringsData.FamilyRoleName;
      txtFamilyRoleNamePlural.current.value = accountStringsData.FamilyRoleNamePlural;
      txtGroupsRoleName.current.value = accountStringsData?.GroupsButtonText || 'Group';
      txtGroupsRoleNamePlural.current.value = accountStringsData?.GroupsPluralButtonText || 'Groups';
      txtUserRoleName.current.value = accountStringsData.UserRoleName;
      txtUserRoleNamePlural.current.value = accountStringsData.UserRoleNamePlural;
      txtOnDutyName.current.value = accountStringsData.OnDutyName;
      txtCallButtonText.current.value = accountStringsData.CallButtonText;
      txtCallButtonText2.current.value = accountStringsData.CallButtonText2;
      txtEmergencyBtnLabel.current.value = accountStringsData.EmergencyButtonText;
      txtMemoryBoxLabel.current.value = accountStringsData.MemoryBoxButtonText;
      txtFamilyMemberLabel.current.value = accountStringsData.FamilyCallButtonText;
      txtBiometricsLabel.current.value = accountStringsData.BiometricsButtonText;
      txtAddFamilyButtonLabel.current.value = accountStringsData.AddFamilyButtonText;
      txtTimeoutMessage.current.value = accountStringsData.RequestTimeoutMessageTextForPatient;
      setAccountCallTimeoutMessageLength(accountStringsData.RequestTimeoutMessageTextForPatient.length);
      txtOffHoursMessage.current.value = accountStringsData.OffHoursMessage;
    }
  };

  const handleChangeAccountLanguage = () => {
    console.log('handleChangeAccountLanguage this.state.accountSelectedLanguage)', accountSelectedLanguage);
    console.log('handleChangeAccountLanguage this.selectAccountLanguage.current.value)', selectAccountLanguage.current.value);
    const selectedLang = selectAccountLanguage.current.value;
    setLoadingStrings(true);
    setAccountSelectedLanguage(selectedLang.toUpperCase());

    CareAccountStore.getAccountStringsConfig(accountId, selectedLang.toUpperCase(), (response) => {
      console.log('CareAccountStore.getAccountStringsConfig callback', response);
      setLoadingStrings(false);
      if (response.ok) {
        loadAccountStringsConfig(response);
      }
    });
  };

  const handleSaveClientRolename = () => {
    if (txtClientRoleName.current.value == '') {
      Message.show('Enter the Client RoleName please');
      return;
    }
    console.log('handleSaveClientRolename');

    CareAccountStore.updateAccountStringsConfig(accountId, accountSelectedLanguage, { ClientRoleName: txtClientRoleName.current.value }, (response) => {
      if (response.ok) {
        Message.show('Client Role Name updated');
      } else {
        Message.show('Error updating Client Role Name');
      }
    });
  };

  const handleSaveClientRolenamePlural = () => {
    if (txtClientRoleNamePlural.current.value == '') {
      Message.show('Enter the Plural Client RoleName please');
      return;
    }
    console.log('handleSaveClientRolenamePlural');

    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        ClientRoleNamePlural: txtClientRoleNamePlural.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Client Role Name Plural updated');
        } else {
          Message.show('Error updating Client Role Name Plural');
        }
      },
    );
  };

  const handleSaveFamilyRolename = () => {
    if (txtFamilyRoleName.current.value == '') {
      Message.show('Enter the Family RoleName please');
      return;
    }
    console.log('handleSaveFamilyRolename');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        FamilyRoleName: txtFamilyRoleName.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Family Role Name updated');
        } else {
          Message.show('Error updating Family Role Name');
        }
      },
    );
  };
  const handleSaveFamilyRolenamePlural = () => {
    if (txtFamilyRoleNamePlural.current.value == '') {
      Message.show('Enter the Family RoleName please');
      return;
    }
    console.log('handleSaveFamilyRolenamePlural');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        FamilyRoleNamePlural: txtFamilyRoleNamePlural.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Family Role Name Plural updated');
        } else {
          Message.show('Error updating Family Role Name Plural');
        }
      },
    );
  };

  const handleSaveGroupsRolename = () => {
    if (txtGroupsRoleName.current.value == '') {
      Message.show('Enter the Groups RoleName please');
      return;
    }
    console.log('handleSaveGroupsRolename');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        GroupsButtonText: txtGroupsRoleName.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Groups Role Name updated');
        } else {
          Message.show('Error updating Groups Role Name');
        }
      },
    );
  };

  const handleSaveGroupsRolenamePlural = () => {
    if (txtGroupsRoleNamePlural.current.value == '') {
      Message.show('Enter the Groups RoleName plural please');
      return;
    }
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        GroupsPluralButtonText: txtGroupsRoleNamePlural.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Groups Role Name plural updated');
        } else {
          Message.show('Error updating Groups Role plural Name');
        }
      },
    );
  };

  const handleSaveUserRoleName = () => {
    if (txtUserRoleName.current.value == '') {
      Message.show('Enter the User RoleName please');
      return;
    }
    console.log('handleSaveUserRoleName');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        UserRoleName: txtUserRoleName.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('User Role Name updated');
        } else {
          Message.show('Error updating User Role Name');
        }
      },
    );
  };

  const handleSaveUserRoleNamePlural = () => {
    if (!txtUserRoleNamePlural.current.value) {
      Message.show('Enter the Plural User RoleName please');
      return;
    }

    console.log('handleSaveUserRoleNamePlural');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        UserRoleNamePlural: txtUserRoleNamePlural.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('User Role Name Plural updated');
        } else {
          Message.show('Error updating User Role Name Plural');
        }
      },
    );
  };

  const handleUpdateRequestTimeoutMessage = () => {
    if (txtTimeoutMessage.current.value.length > 120) {
      Message.show('Please enter a shorter message.');
      txtTimeoutMessage.current.focus();
      return;
    }
    console.log('handleUpdateRequestTimeoutMessage');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        RequestTimeoutMessageTextForPatient: txtTimeoutMessage.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Request Timeout Message updated');
        } else {
          Message.show('Error updating Request Timeout Message ');
        }
      },
    );
  }

  const handleUpdateOffHoursMessage = () => {
    if (txtOffHoursMessage.current.value == '') {
      Message.show('Enter the Off Hours message please.');
      txtOffHoursMessage.current.focus();
      return;
    }
    
    setUpdatingMessage(true);
    console.log('handleUpdateRequestTimeoutMessage');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        OffHoursMessage: txtOffHoursMessage.current.value,
      },
      (response) => {
        setUpdatingMessage(false);
        if (response.ok) {
          Message.show('Off Hours Message updated');
        } else {
          Message.show('Error updating Off Hours Message');
        }
      },
    );
  }

  const handleSaveOnDutyName = () => {
    if (txtOnDutyName.current.value == '') {
      Message.show('Enter the Plural User RoleName please');
      return;
    }

    console.log('handleSaveUserRoleNamePlural');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        OnDutyName: txtOnDutyName.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('On Duty Name updated');
        } else {
          Message.show('Error updating On Duty Name');
        }
      },
    );
  };

  const handleSaveCallButtonText = () => {
    if (txtCallButtonText.current.value == '') {
      Message.show('Enter the Call Action Button Text please');
      return;
    }
    console.log('handleSaveCallButtonText');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        CallButtonText: txtCallButtonText.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Call Button Text updated');
        } else {
          Message.show('Error updating Call Button Text');
        }
      },
    );
  };

  const handleSaveCallButtonText2 = () => {
    if (txtCallButtonText2.current.value == '') {
      Message.show('Enter the Call Action Button Text please');
      return;
    }
    console.log('handleSaveCallButtonText2');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        CallButtonText2: txtCallButtonText2.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Call Action Button Text 2 updated');
        } else {
          Message.show('Error updating Call Action Button Text 2');
        }
      },
    );
  };

  const handleUpdateEmergencyBtnLabel = () => {
    if (txtEmergencyBtnLabel.current.value == '') {
      Message.show('Enter the Button text please');
      txtEmergencyBtnLabel.current.focus();
      return;
    }
    console.log('handleUpdateEmergencyBtnLabel');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        EmergencyButtonText: txtEmergencyBtnLabel.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Emergency Button Text updated');
        } else {
          Message.show('Error updating Emergency Button Text');
        }
      },
    );
  };

  const openAddLanguageModal = () => {
    console.log('openAddLanguageModal');
    selectNewAccountLanguage.current.value = '';
    //@ts-ignore
    window.$('#modalNewAccountLanguage').modal('open');
  };

  const handleCloseNewLanguageModal = () => {
    //@ts-ignore
    window.$('#modalNewAccountLanguage').modal('close');
  };

  const handleUpdateMemoryBoxLabel = () => {
    if (txtMemoryBoxLabel.current.value == '') {
      Message.show('Enter the Memory box button text please');
      txtMemoryBoxLabel.current.focus();
      return;
    }
    console.log('handleUpdateMemoryBoxLabel');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        MemoryBoxButtonText: txtMemoryBoxLabel.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Memory box button text updated');
        } else {
          Message.show('Error updating Memory box button text');
        }
      },
    );
  }

  const handleSubmitNewLanguage = () => {
    console.log('handleSubmitNewLanguage');
    const newSelectedLanguage = selectNewAccountLanguage.current.value;
    if (newSelectedLanguage == '') {
      Message.show('Select one new Language please');
      return;
    }
    //@ts-ignore
    window.$('#modalNewAccountLanguage').modal('close');
    setLoadingStrings(true);

    CareAccountStore.addAccountStringsConfig(accountId, newSelectedLanguage, (response) => {
      console.log('CareAccountStore.addAccountStringsConfig callback', response);
      setLoadingStrings(false);
      if (response.ok) {
        Message.show('New Language Added');

        CareAccountStore.getAccountActiveLanguages(accountId, (response) => {
          console.log('CareAccountStore.getAccountActiveLanguages callback', response);
          if (response.ok && response.accountLanguages) {
            setAccountActiveLanguages(response.accountLanguages);
            selectAccountLanguage.current.value = newSelectedLanguage;
            handleChangeAccountLanguage();
          }
        });
        fetchAvailableLanguages();
      } else {
        Message.show('Error adding new Language');
      }
    });
  };

  const fetchAvailableLanguages = () => {
    CareAccountStore.getAccountAvailableLanguages(accountId, (response) => {
      console.log('CareAccountStore.getAccountAvailableLanguages callback', response);
      if (response.ok && response.accountAvailableLanguages) {
        setAccountAvailableLanguages(response.accountAvailableLanguages);
      }
    });
  };

  const handleUpdateFamilyMemberLabel = () => {
    if (txtFamilyMemberLabel.current.value == '') {
      Message.show('Enter the Family Member button text please');
      txtFamilyMemberLabel.current.focus();
      return;
    }
    console.log('handleUpdateFamilyMemberLabel');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        FamilyCallButtonText: txtFamilyMemberLabel.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Call Family Member button text updated');
        } else {
          Message.show('Error updating Call Family Member button text');
        }
      },
    );
  }

  const handleUpdateBiometricsLabel = () => {
    if (txtBiometricsLabel.current.value == '') {
      Message.show('Enter the Biometrics button text please');
      txtBiometricsLabel.current.focus();
      return;
    }
    console.log('handleUpdateFamilyMemberLabel');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        BiometricsButtonText: txtBiometricsLabel.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show('Biometrics button text updated');
        } else {
          Message.show('Error updating Biometrics button text');
        }
      },
    );
  }

  const handleUpdateAddFamilyButtonLabel = () => {
    if (txtAddFamilyButtonLabel.current.value == '') {
      Message.show("Enter the 'Add Family' button text please");
      txtAddFamilyButtonLabel.current.focus();
      return;
    }
    console.log('handleUpdateFamilyMemberLabel');
    CareAccountStore.updateAccountStringsConfig(
      accountId,
      accountSelectedLanguage,
      {
        AddFamilyText: txtAddFamilyButtonLabel.current.value,
      },
      (response) => {
        if (response.ok) {
          Message.show("'Add Family' button text updated");
        } else {
          Message.show("Error updating 'Add Family' button text");
        }
      },
    );
  }

  const handleUpdateRequestTimeoutMessageLength = () => {
    setAccountCallTimeoutMessageLength(txtTimeoutMessage.current.value.length);
  }

  const handleChangeNewAccountLanguage = () => {
  }


  useEffect(() => {
    if (accountData) {
    }
    return () => {};
  }, [accountData]);

  useEffect(() => {
     //@ts-ignore
     window.$('.modalNewAccountLanguage').modal({
      dismissible: true,
      complete: function () {}, // Callback for Modal close
    });
    CareAccountStore.on('onGetAccountData', onGetAccountDataAction);
    refreshAccountData();

    CareAccountStore.getAccountActiveLanguages(accountId, (response) => {
      console.log('CareAccountStore.getAccountActiveLanguages callback', response);
      if (response.ok && response.accountLanguages) {
        setAccountActiveLanguages(response.accountLanguages);
      }
    });

    fetchAvailableLanguages();
    return () => {
      CareAccountStore.removeListener('onGetAccountData', onGetAccountDataAction);
    };
  }, []);

  return (
    <div className={'row '}>
      {/* First Column */}
      <div className="col s5 m5 l5 xl5 no-margin">
        <div className="row vbottom-align no-margin ">
          <div className="col s5 m5 l4 no-margin top-separated">
            <span className="nucleus-labels-small span-text-bottom">Language</span>
          </div>
          <div className="col s6 m6 l7 no-margin control-panels-container">
            <select ref={selectAccountLanguage} className="browser-default accountSelect" onChange={handleChangeAccountLanguage}>
              <option value={''} selected disabled>
                {' '}
                {'Select Language'}
              </option>
              {accountActiveLanguages.map((lang, key) => {
                return (
                  <option key={lang.LocaleTag} value={lang.LocaleTag}>
                    {lang.Description}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col s1 m1 l1 no-margin "></div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s12 no-margin">
            <span className="nucleus-labels-bold-height"> Role Name Configurations: </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Patient </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtClientRoleName} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveClientRolename}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Patients </span>{' '}
          </div>
          <div className="col s5 no-margin">
            <input ref={txtClientRoleNamePlural} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveClientRolenamePlural}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Family Member </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtFamilyRoleName} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveFamilyRolename}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Family Members </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtFamilyRoleNamePlural} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveFamilyRolenamePlural}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Group </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtGroupsRoleName} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveGroupsRolename}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Group(s) </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtGroupsRoleNamePlural} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveGroupsRolenamePlural}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> User </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtUserRoleName} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveUserRoleName}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> User(s) </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtUserRoleNamePlural} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveUserRoleNamePlural}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> On Duty </span>
          </div>
          <div className="col s5 no-margin">
            <input ref={txtOnDutyName} type="text" className="validate nucleus-input-form accountTextShort" />
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn" onClick={handleSaveOnDutyName}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align-not-height no-margin ">
          <div className="col s2 no-margin">
            <span>&nbsp;</span>
          </div>
        </div>
      </div>

      {/* Second Column */}
      <div className="col s5 m5 l5 xl5 no-margin">
        <div className="row vbottom-align no-margin ">
          <div className="col s10 m10 l10 no-margin top-separated">
            <span className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={openAddLanguageModal}>
              Add Language
            </span>
          </div>
          <div className="col s2 m2 l2 no-margin top-separated"></div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s12 no-margin">
            <span className="nucleus-labels-bold-height"> Device Buttons Name Configurations: </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Request Call 1 (Default) </span>
          </div>
          <div className="col s5 no-margin">
            <input
              ref={txtCallButtonText}
              type="text"
              className="validate nucleus-input-form accountTextShort"
              placeholder="Button Text"
            />
          </div>
          <div className="col s2 no-margin">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleSaveCallButtonText}
            >
              <i className="material-icons nucleus-floating-btn-icon"> save </i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Request Call 2 (Telehelp) </span>
          </div>
          <div className="col s5 no-margin">
            <input
              ref={txtCallButtonText2}
              type="text"
              className="validate nucleus-input-form accountTextShort"
              placeholder="Button Text"
            />
          </div>
          <div className="col s2 no-margin">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleSaveCallButtonText2}
            >
              <i className="material-icons nucleus-floating-btn-icon"> save </i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Emergency Call </span>{' '}
          </div>
          <div className="col s5 no-margin">
            <input
              ref={txtEmergencyBtnLabel}
              type="text"
              className="validate nucleus-input-form accountTextShort"
              placeholder="Button Text"
            />
          </div>
          <div className="col s2 no-margin">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleUpdateEmergencyBtnLabel}
            >
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Memory box </span>{' '}
          </div>
          <div className="col s5 no-margin">
            <input
              ref={txtMemoryBoxLabel}
              type="text"
              className="validate nucleus-input-form accountTextShort"
              placeholder="Button Text"
            />
          </div>
          <div className="col s2 no-margin">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleUpdateMemoryBoxLabel}
            >
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Family Members </span>{' '}
          </div>
          <div className="col s5 no-margin">
            <input
              ref={txtFamilyMemberLabel}
              type="text"
              className="validate nucleus-input-form accountTextShort"
              placeholder="Button Text"
            />
          </div>
          <div className="col s2 no-margin">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleUpdateFamilyMemberLabel}
            >
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Biometrics </span>{' '}
          </div>
          <div className="col s5 no-margin">
            <input
              ref={txtBiometricsLabel}
              type="text"
              className="validate nucleus-input-form accountTextShort"
              placeholder="Button Text"
            />
          </div>
          <div className="col s2 no-margin">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleUpdateBiometricsLabel}
            >
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin">
          <div className="col s5 no-margin">
            <span className="nucleus-labels"> Add Family </span>{' '}
          </div>
          <div className="col s5 no-margin">
            <input
              ref={txtAddFamilyButtonLabel}
              type="text"
              className="validate nucleus-input-form accountTextShort"
              placeholder="Button Text"
            />
          </div>
          <div className="col s2 no-margin">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleUpdateAddFamilyButtonLabel}
            >
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>
      </div>

      <div className="col s2 m2 l2 xl2 no-margin">
        <div className="row vbottom-align no-margin ">
          {loadingStrings && (
            <div className={'row center-align'}>
              <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="36" thickness={100} />
            </div>
          )}
        </div>
      </div>

      {/*Row under Columns*/}
      <div className="row">
        <div className="row vbottom-align no-margin top-separated">
          <div className="col s12 no-margin">
            <span className="nucleus-labels-bold-height"> Custom Message Configurations: </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin" >
            <span className="nucleus-labels"> Call Timeout Message </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              ref={txtTimeoutMessage}
              type="text"
              className="validate nucleus-input-form accountTextMedium no-margin-bottom"
              placeholder="Call timeout message"
              onChange={handleUpdateRequestTimeoutMessageLength}
            />
            <br />
            <span className="nucleus-labels gray nucleus-font-14 a-much-right">{' Message length ' + accountCallTimeoutMessageLength + ' of 120 chars. '}</span>
          </div>
          <div className="col s2 no-margin">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={handleUpdateRequestTimeoutMessage}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s10 no-margin">
            <span className="nucleus-labels"> Off hours Message </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              ref={txtOffHoursMessage}
              type="text"
              className="validate nucleus-input-form accountTextMedium"
              placeholder="Off hours message"
            />
          </div>
          <div className="col s2 m1 no-margin a-little-right">
            <span
              className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
              onClick={handleUpdateOffHoursMessage}
            >
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>
      </div>

      <div id="modalNewAccountLanguage" className="modal modalNewAccountLanguage">
        <div className="modal-content modal-content-delete center-align">
          <h3 className="nucleus-page-subtitle">Add a new Language to the Account</h3>
          <br />
          <br />
          <div className="row center-align">
            <div className="col s2"></div>
            <div className="col s8 left-align">
              <span className="nucleus-labels">
                <b>Select a Language</b>
              </span>
            </div>
            <div className="col s2"></div>
          </div>
          <div className="row center-align">
            <div className="col s2"></div>
            <div className="col s8 left-align">
              {/*<input id="txtNewAccountDeviceMac" ref="txtNewAccountDeviceMac" type="text" className="validate nucleus-search"/><br/>*/}
              <select ref={selectNewAccountLanguage} className="browser-default accountSelect" onChange={handleChangeNewAccountLanguage}>
                <option value={''} selected disabled>
                  {' '}
                  {'Select Language'}
                </option>
                {accountAvailableLanguages.map((lang) => {
                  return (
                    <option key={lang.Tag} value={lang.Tag}>
                      {lang.Description}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col s2"></div>
          </div>
          <div className="row ">
            <div className="col s12 center-align ">
              <div className="col s6 right-align">
                <span className="txt-white nucleus-font-small nucleus-link" onClick={handleCloseNewLanguageModal}>
                  Cancel
                </span>
              </div>
              <div className="col s6 left-align">
                <span className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={handleSubmitNewLanguage}>
                  Confirm
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
